import React, { useState } from 'react';
import API from '../../Services/BaseService';
// import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
// import Loading from '../../Component/Loading/Loading';
import checkImg from '../../../src/assets/img/check.png';
import errorImg from '../../../src/assets/img/uncheck.png';

const SelectAllPopup = (props) => {

    // const userData = useSelector(state => state.LoginReducer.payload);

    const [toggleZipcodePopupOpen, setToggleZipcodePopupOpen] = useState(true);
    const [alertimg, setAlertImg] = useState("");

    const [popupTitle, /* setPopupTitle */] = useState("");
    const [popupMsg, /* setPopupMsg */] = useState("");
    const [popupType, /* setPopupType */] = useState("");
    const [popupActionType, /* setPopupActionType */] = useState("");
    const [popupActionValue, /* setPopupActionValue */] = useState("");

    const [alertmessage, setAlertMessage] = useState("");
    const [alerttitle, setAlertTitle] = useState("");
    // const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    // const [loading, /* setLoading */] = useState(true)
    const [password, setPassword] = useState("");

    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const getSelectedReport = () => {

        let request = {
            pagestatus: false,
            customerlist: props.PageselectAll === false || props.PageselectAll === "" ? props.selectedValues : props.FilterSelect,
            status: props.Status,
            active: props.Status === "Active" ? 1 : 4,
            password:props.Filterstatus == "Dontcall"?password:""
        }
        console.log("selcted", props, request);

        {
            API.post("customerlistvoid/update", request)
                .then((response) => {
                    // console.log("resresponse.data.data", response.data.data)
                    setToggleZipcodePopupOpen(false);

                    if (response.data.success == true) {


                        setAlertTitle("Customer");
                        if (props.Status == "Active") {
                            setAlertMessage("Status Active Succesfully");
                            setAlertImg(checkImg);
                            props.reCallFunction();
                        }
                        else {
                            setAlertMessage("Status Inactive Succesfully");
                            setAlertImg(checkImg);
                            props.reCallFunction();
                        }
                        //setAlertMessage("Customer Created Succesfully");

                    } else {
                        setAlertTitle("Customer");
                        setAlertImg(errorImg);
                        setAlertMessage(response.data.error.err);
                    }
                });

        }
    };

    const handleClose = () => {
        props.toggle();
        props.reCallFunction();
    }

    return (

        <div>
            <div>
                {toggleZipcodePopupOpen ?
                    (<>
                        <div className="main-contents">
                            <div className="modal-bodys">
                                {/* {loading ? <Loading /> : */}
                                <div className="row">
                                    <div className="back-heading">
                                        <h5 className="heading-text">Confirmation</h5>
                                        <p className='Para-text'>Are You Sure, Do you want to Change the status</p>
                                        <p className='Para-text'>Don&apos;t call customer count: {`${props.selectedDontCall > 0 ? props.selectedDontCall : 0}`}</p>

                                    </div>
                                </div>
                                {/* } */}
                                {props.selectedDontCall>0 &&
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div className=" col-lg-6 me-2 text-center">
                                            <label>Enter Password</label>
                                            <input type="text" className="form-control" value={password} placeholder="" onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                    </div>
                                }
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-smloutline-danger me-2" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn form-submit-btn" onClick={getSelectedReport}>Save </button>
                                </div>
                            </div>
                        </div>
                    </>) :
                    (<div className='uploadpopup'>
                        <div className="Commonfull-icon">
                            <img className={alertimg === checkImg ? "successImg " : "errorImg"} src={alertimg} />
                        </div>
                        <div className="CommonModalbody">
                            <h2>{alerttitle}</h2>
                            <p>{alertmessage}</p>
                        </div>
                        <div className="CommonModalfooter ">
                            <div className="CommonModalfooter session">
                                <button className="btn btn-smlprimary" onClick={props.toggle}>Ok</button>
                            </div>
                        </div>
                    </div>
                    )}
            </div>
            {
                isOpenPopup && (
                    <CommonPopup
                        handleClose={togglePopup}
                        popupTitle={popupTitle}
                        popupMsg={popupMsg}
                        popupType={popupType}
                        popupActionType={popupActionType}
                        popupActionValue={popupActionValue}
                    // popupActionPath={popupActionPath}
                    />
                )
            }
        </div>



    );
};

export default SelectAllPopup